















































import { ResponseListMaster } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { masterServices } from "@/services/master.service";
import Vue, { PropType } from "vue";
import {
  OPTION_COSTING_METHOD,
  OPTION_BASIC_FEATURE,
} from "./constant/optioninventory.constant";
import { BASIC_FEATTURE } from "./enums/option-inventory.enum";
import { COMPONENT_NAME } from "./Preferences.vue";
export interface IPrefInventory {
  inventory_costing_method: string;
  basic: string[];
}
export default Vue.extend({
  name: "PreferencesInventory",
  props: {
    firstLoad: Boolean,
    dataFirstLoad: {} as PropType<IPrefInventory>,
  },
  watch: {
    dataFirstLoad: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!this.firstLoad && newValue) {
          this.valPref.basic = newValue.basic;
          this.valPref.inventory_costing_method =
            newValue.inventory_costing_method;
          this.$emit("onChangeLoad", {
            name: COMPONENT_NAME.PREF_INVENTORY,
            val: true,
          });
        }
      },
    },
  },
  data() {
    return {
      // optCostingMethod: OPTION_COSTING_METHOD,
      optCostingMethod: [] as ResponseListMaster[],
      optBasicFeature: OPTION_BASIC_FEATURE,
      invCheckedList: [],
      valPref: {
        inventory_costing_method: "",
        basic: [] as string[],
      } as IPrefInventory,
    };
  },
  methods: {
    generateLabel(key: string | BASIC_FEATTURE): string {
      if (key === BASIC_FEATTURE.INVENTORY_SHOW_PRODUCT_STOCK)
        return this.$t("lbl_show_product_stock").toString();
      else if (key === BASIC_FEATTURE.INVENTORY_MULTI_WAREHOUSE)
        return this.$t("lbl_multi_warehouse").toString();
      else if (key === BASIC_FEATTURE.INVENTORY_MULTI_UOM)
        return this.$t("lbl_multi_uom").toString();
      else if (key === BASIC_FEATTURE.INVENTORY_SERIAL_NUMBER)
        return this.$t("lbl_serial_number_product").toString();
      return key;
    },
    onChangeCosting(value, option): void {
      this.valPref.inventory_costing_method = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_INVENTORY,
        val: this.valPref,
      });
    },
    onChecked(checkedValues): void {
      this.valPref.basic = checkedValues;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_INVENTORY,
        val: this.valPref,
      });
    },
  },
  created() {
    const paramsCostingMethods: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      name: "COSTING_METHOD",
    };
    masterServices
      .listMaster(paramsCostingMethods)
      .then((responseCostinMethod) => {
        this.optCostingMethod = responseCostinMethod;
      });
  },
});
