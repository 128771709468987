import { OptionModel } from "@/models/interface/common.interface";
import { BASIC_FEATTURE } from "../enums/option-inventory.enum";

export const OPTION_COSTING_METHOD: OptionModel[] = [
    {
        label: 'FIFO',
        value: 0
    },
    {
        label: 'AVERAGE',
        value: 1
    }
];

export const OPTION_BASIC_FEATURE: OptionModel[] = [
    {
        value: BASIC_FEATTURE.INVENTORY_SHOW_PRODUCT_STOCK
    },
    {
        value: BASIC_FEATTURE.INVENTORY_MULTI_WAREHOUSE
    },
    {
        value: BASIC_FEATTURE.INVENTORY_MULTI_UOM
    },
    {
        value: BASIC_FEATTURE.INVENTORY_SERIAL_NUMBER
    }
];
